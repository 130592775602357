// search.selectors.ts

import { ArkSearchBarFilter } from '@ark';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GthPlaceModel } from '@sentinels/models/place';
import { distanceBetween } from 'geofire-common';

import { selectUser } from '../auth/selectors';
import { generateBoundsFromLatLng, isLocationWithinBounds } from '../helpers/geolocation';
import * as State from './state';

export const selectPlacesState = createFeatureSelector<State.PlacesState>('places');

export const selectAllPlaces = createSelector(selectPlacesState, (state) => {
  return Object.values(state?.places || []);
});

export const selectPlacesById = (id: string) =>
  createSelector(selectAllPlaces, (places) => {
    return places.find((p) => p.id === id);
  });

// TODO: Add search filter to this
export const selectFilteredPlaces = (filter?: ArkSearchBarFilter) =>
  createSelector(selectAllPlaces, (places) => {
    if (!places) return [];
    if (!filter?.gameType?.length && !filter?.searchText) return places;

    return places.filter((place) => {
      const matchGameType = filter.gameType?.length ?
        filter.gameType.some((type) => type === place.type) :
        true;

      const matchSearchText = filter.searchText ?
        place.title.toLowerCase().includes(filter.searchText.toLowerCase()) ||
          place.description.toLowerCase().includes(filter.searchText.toLowerCase()) :
        true;

      return matchGameType && matchSearchText;
    });
  });

export const selectPlacesByLatLngForAuthUser = (
  start = 0,
  end?: number,
  filter?: ArkSearchBarFilter,
) =>
  createSelector(
    selectUser,
    selectFilteredPlaces(filter),
    (user, places): { places: GthPlaceModel[]; total: number } => {
      if (!user?.defaultCity || !places) return { places: [], total: 0 };

      const lat = user.defaultCity.lat;

      const lng = user.defaultCity.lng;

      const radiusInM = 200 * 1000;

      const center: [number, number] = [lat, lng];

      const filteredPlaces = places.filter((place) => {
        if (!place?.address.lat || !place?.address.lng) return false;

        const distanceInKm = distanceBetween([place.address.lat, place.address.lng], center);
        const distanceInM = distanceInKm * 1000;
        return distanceInM <= radiusInM;
      });

      const sortedPlaces = filteredPlaces;
      /** todo: add created property to place model */
      //   .sort((a, b) => {
      //   return b.created.nanoseconds - a.created.nanoseconds;
      // });

      const slicesPlaces = sortedPlaces.slice(start, end);

      return { places: slicesPlaces, total: filteredPlaces.length };
    },
  );

export const selectPlacesByLatLng = (
  lat: number,
  lng: number,
  bounds: google.maps.LatLngBoundsLiteral,
  limit = 100,
  filter?: ArkSearchBarFilter,
) =>
  createSelector(
    selectFilteredPlaces(filter),
    (places): { places: GthPlaceModel[]; total: number } => {
      bounds = bounds || generateBoundsFromLatLng(lat, lng, 200);

      const filteredPlaces = places.filter((place) =>
        isLocationWithinBounds(
          bounds,
          place,
          (p) => p.address.lat,
          (p) => p.address.lng,
        ),
      );
      const sortedPlaces = filteredPlaces;
      /** todo: add created property to place model */
      //   .sort((a, b) => {
      //   return b.created.nanoseconds - a.created.nanoseconds;
      // });

      const slicesPlaces = sortedPlaces.slice(0, limit);

      return { places: slicesPlaces, total: filteredPlaces.length };
    },
  );

export const selectPlaceEventsByPlaceId = (placeId: string) =>
  createSelector(selectPlacesState, (state) => {
    const events = state.events.filter((event) => event.placeId === placeId);
    return events;
  });
